<template>
  <div>
    <b-row v-if="channelInfo.user">
      <b-col cols="12" xl="12" lg="8" md="7">
        <b-card>
          <b-row>
            <!-- User Info: Left col -->
            <b-col
              cols="12"
              xl="4"
              class="d-flex justify-content-between flex-column"
            >
              <!-- User Avatar & Action Buttons -->
              <div class="d-flex justify-content-start">
                <b-link
                  :href="channel_url"
                  target="_blank"
                  class="channel-avatar"
                >
                  <b-avatar
                    :src="channelInfo.avatar"
                    size="116px"
                    rounded="circle"
                  />
                </b-link>
                <div class="d-flex flex-column ml-1">
                  <div class="mb-1 mt-1">
                    <span
                      class="card-text font-weight-bold"
                      style="font-size: 18px"
                      >{{ channelInfo.nickname }}</span
                    >
                    <br />
                    <span
                      class="card-text font-weight-bold"
                      style="font-size: 14px"
                      >{{ channelInfo.description }}</span
                    >
                  </div>
                </div>
              </div>
            </b-col>
            <b-col class="mt-1" xl="5">
              <table class="mt-2 mt-xl-0">
                <tr>
                  <th class="pb-50">
                    <feather-icon icon="PhoneIcon" class="mr-75" />
                    <span class="font-weight-bold"
                      >{{ $t("Pages.Studio.Label.phone_number") }}:</span
                    >
                  </th>
                  <td class="pb-50 text-capitalize px-1">
                    {{ channelInfo.user.mobile }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon icon="MailIcon" class="mr-75" />
                    <span class="font-weight-bold"
                      >{{ $t("Pages.Studio.Label.email_address") }}:</span
                    >
                  </th>
                  <td class="pb-50">
                    {{ channelInfo.user.email }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon icon="UserPlusIcon" class="mr-75" />
                    <span class="font-weight-bold"
                      >{{ $t("Pages.Studio.Label.Subscriber") }}:</span
                    >
                  </th>
                  <td class="pb-50">{{ channelInfo.subscribers }}</td>
                </tr>
              </table>
            </b-col>
            <b-col class="mt-1" xl="3">
              <table class="mt-2 mt-xl-0">
                <tr>
                  <th class="pb-50">
                    <feather-icon icon="WatchIcon" class="mr-75" />
                    <span class="font-weight-bold"
                      >{{ $t("Pages.Studio.Label.Videos") }}:</span
                    >
                  </th>
                  <td class="pb-50 px-1">{{ channelInfo.videos }}</td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon icon="EyeIcon" class="mr-75" />
                    <span class="font-weight-bold"
                      >{{ $t("Pages.Studio.Label.View") }}:</span
                    >
                  </th>
                  <td class="pb-50">{{ channelInfo.views }}</td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon icon="MapPinIcon" class="mr-75" />
                    <span class="font-weight-bold"
                      >{{ $t("Pages.Studio.Label.Country") }}:</span
                    >
                  </th>
                  <td class="pb-50">{{ channelInfo.country }}</td>
                </tr>
              </table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12" sm="12">
        <b-tabs>
          <b-tab active>
            <template #title>
              <span>{{ $t("Pages.Studio.Title.Overview") }}</span>
            </template>
            <b-overlay :show="showOverView" rounded="sm">
              <b-row>
                <b-col lg="6" sm="6">
                  <statistic-card-with-area-chart
                    v-if="data.views.status"
                    icon="RadioIcon"
                    :statistic="kFormatter(data.views.analyticsData)"
                    :statistic-title="$t('Pages.Studio.Title.View')"
                    :chart-data="data.views.series"
                  />
                </b-col>
                <b-col lg="6" sm="6">
                  <statistic-card-with-area-chart
                    v-if="data.watchtime.status"
                    icon="WatchIcon"
                    color="warning"
                    :statistic="kFormatter(data.watchtime.analyticsData)"
                    :statistic-title="$t('Pages.Studio.Title.watch_time')"
                    :chart-data="data.watchtime.series"
                  />
                </b-col>
                <b-col lg="6" sm="6">
                  <statistic-card-with-area-chart
                    v-if="data.subscribers.status"
                    icon="UserPlusIcon"
                    color="success"
                    :statistic="kFormatter(data.subscribers.analyticsData)"
                    :statistic-title="$t('Pages.Studio.Title.Subscriber')"
                    :chart-data="data.subscribers.series"
                  />
                </b-col>
                <b-col lg="6" sm="6">
                  <statistic-card-with-area-chart
                    v-if="data.subscriberslost.status"
                    icon="UserMinusIcon"
                    color="danger"
                    :statistic="kFormatter(data.subscriberslost.analyticsData)"
                    :statistic-title="
                      $t('Pages.Studio.Title.missed_subscriber')
                    "
                    :chart-data="data.subscriberslost.series"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="6" sm="6">
                  <statistic-card-with-area-chart
                    v-if="data.like.status"
                    icon="ThumbsUpIcon"
                    color="success"
                    :statistic="kFormatter(data.like.analyticsData)"
                    :statistic-title="$t('Pages.Studio.Title.Like')"
                    :chart-data="data.like.series"
                  />
                </b-col>
                <b-col lg="6" sm="6">
                  <statistic-card-with-area-chart
                    v-if="data.dislike.status"
                    icon="ThumbsDownIcon"
                    color="danger"
                    :statistic="kFormatter(data.dislike.analyticsData)"
                    :statistic-title="$t('Pages.Studio.Title.Dislike')"
                    :chart-data="data.dislike.series"
                  />
                </b-col>
                <b-col lg="6" sm="6">
                  <statistic-card-with-area-chart
                    v-if="data.share.status"
                    icon="Share2Icon"
                    :statistic="kFormatter(data.share.analyticsData)"
                    :statistic-title="$t('Pages.Studio.Title.Share')"
                    :chart-data="data.share.series"
                  />
                </b-col>
                <b-col lg="6" sm="6">
                  <statistic-card-with-area-chart
                    v-if="data.comment.status"
                    icon="MessageSquareIcon"
                    color="warning"
                    :statistic="kFormatter(data.comment.analyticsData)"
                    :statistic-title="$t('Pages.Studio.Title.Comment')"
                    :chart-data="data.comment.series"
                  />
                </b-col>
              </b-row>
            </b-overlay>
            <b-row v-show="hasVideoData">
              <b-col lg="4" sm="12">
                <b-card no-body>
                  <b-card-header>
                    <h4 class="mb-0">
                      {{ $t("Pages.Studio.Title.mastertube_score") }}
                    </h4>
                    <b-button variant="primary" @click="moreAnalyze">{{
                      $t("Pages.Studio.Button.more_analyze")
                    }}</b-button>
                  </b-card-header>
                  <!-- apex chart -->
                  <vue-apex-charts
                    type="radialBar"
                    height="300"
                    class="my-2"
                    :options="goalOverviewRadialBar"
                    :series="goalOverview.series"
                  />
                  <b-row class="text-center mx-0">
                    <b-col
                      cols="6"
                      class="border-top d-flex align-items-between border-right flex-column py-1"
                    >
                      <b-card-text class="text-muted mb-2">
                        {{ $t("Pages.Studio.Label.Videos") }}
                      </b-card-text>
                      <h3 class="font-weight-bolder mb-0">
                        {{ goalOverview.videos }}
                      </h3>
                    </b-col>
                    <b-col
                      cols="6"
                      class="border-top d-flex align-items-between flex-column py-1"
                    >
                      <b-card-text class="text-muted mb-2">
                        {{ $t("Pages.Studio.Title.Subscriber") }}
                      </b-card-text>
                      <h3 class="font-weight-bolder mb-0">
                        {{ goalOverview.channel.subscribers }}
                      </h3>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col lg="8" sm="8">
                <b-card no-body v-if="chartjsData.lineChart.status">
                  <b-card-header>
                    <div>
                      <b-card-sub-title>{{
                        $t("Pages.Studio.Title.video_period")
                      }}</b-card-sub-title>
                    </div>
                  </b-card-header>

                  <!-- chart -->
                  <b-card-body>
                    <chartjs-component-line-chart
                      :height="385"
                      :data="chartjsData.lineChart.data"
                      :options="chartjsData.lineChart.options"
                      :plugins="plugins"
                    />
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab @click="loadReachTab">
            <template #title>
              <span>{{ $t("Pages.Studio.Title.Reach") }}</span>
            </template>
            <b-row>
              <b-col lg="12" sm="12">
                <b-card no-body>
                  <b-card-header>
                    <div>
                      <b-card-title class="mb-1">{{
                        viewChart.total
                      }}</b-card-title>
                      <b-card-sub-title>{{
                        $t("Pages.Studio.Title.View")
                      }}</b-card-sub-title>
                    </div>
                  </b-card-header>

                  <b-overlay :show="showChart" rounded="sm">
                    <b-card-body v-if="viewChart.status">
                      <chartjs-component-line-chart
                        :height="430"
                        :data="viewChart.data"
                        :options="viewChart.options"
                        :plugins="plugins"
                      />
                    </b-card-body>
                  </b-overlay>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="6" sm="12">
                <b-card no-body>
                  <b-card-header>
                    <b-card-title>{{
                      $t("Pages.Studio.Title.AdImpressions")
                    }}</b-card-title>
                  </b-card-header>

                  <!-- chart -->
                  <b-card-body v-if="impressionsChart.status">
                    <chartjs-component-bar-chart
                      :height="400"
                      :data="impressionsChart.data"
                      :options="impressionsChart.options"
                    />
                  </b-card-body>
                </b-card>
              </b-col>
              <b-col lg="6" sm="12">
                <b-card no-body>
                  <b-card-header>
                    <b-card-title>{{
                      $t("Pages.Studio.Title.ImpressionBasedCpm")
                    }}</b-card-title>
                  </b-card-header>

                  <!-- chart -->
                  <b-card-body v-if="impressionCpmChart.status">
                    <chartjs-component-bar-chart
                      :height="400"
                      :data="impressionCpmChart.data"
                      :options="impressionCpmChart.options"
                    />
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab @click="loadEngagementTab">
            <template #title>
              <span>{{ $t("Pages.Studio.Title.Interaction") }}</span>
            </template>

            <b-row>
              <b-col lg="6" sm="12">
                <b-card no-body>
                  <b-card-header>
                    <b-card-title>{{
                      $t("Pages.Studio.Title.watch_time")
                    }}</b-card-title>
                  </b-card-header>

                  <!-- chart -->
                  <b-card-body v-if="watchtimeChart.status">
                    <chartjs-component-bar-chart
                      :height="400"
                      :data="watchtimeChart.data"
                      :options="watchtimeChart.options"
                    />
                  </b-card-body>
                </b-card>
              </b-col>
              <b-col lg="6" sm="12">
                <b-card no-body>
                  <b-card-header>
                    <b-card-title>{{
                      $t("Pages.Studio.Title.average_watch_time")
                    }}</b-card-title>
                  </b-card-header>

                  <!-- chart -->
                  <b-card-body v-if="avgdurationChart.status">
                    <chartjs-component-bar-chart
                      :height="400"
                      :data="avgdurationChart.data"
                      :options="avgdurationChart.options"
                    />
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12" sm="12">
                <b-card no-body>
                  <b-card-header>
                    <div>
                      <b-card-title class="mb-1">
                        {{ $t('Pages.Studio.Title.card_impressions') }}
                      </b-card-title>
                      <b-card-sub-title></b-card-sub-title>
                    </div>
                  </b-card-header>

                  <b-overlay :show="showChart" rounded="sm">
                    <b-card-body v-if="cardImpressionsChart.status">
                      <chartjs-component-line-chart
                        :height="430"
                        :data="cardImpressionsChart.data"
                        :options="cardImpressionsChart.options"
                        :plugins="plugins"
                      />
                    </b-card-body>
                  </b-overlay>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4" sm="12">
                <b-card
                  v-if="topVideoWatchTime"
                  no-body
                  class="card-company-table"
                >
                  <b-table
                    :items="topVideoWatchTime"
                    responsive
                    :fields="topVideoWatchTimeFields"
                    class="mb-0"
                  >
                    <!-- company -->
                    <template #cell(name)="data">
                      <div class="d-flex align-items-center">
                        <b-avatar rounded size="32" variant="light-company">
                          <b-img :src="data.item.thumbnail" alt="avatar img"
                        /></b-avatar>
                        <div>
                          <div class="font-weight-bolder mr-2 ml-2">
                            {{ data.item.name }}
                          </div>
                        </div>
                      </div>
                    </template>

                    <!-- count -->
                    <template #cell(count)="data">
                      <div class="d-flex flex-column">
                        <span class="font-weight-bolder mb-25">{{
                          data.item.count
                        }}</span>
                      </div>
                    </template>
                  </b-table>
                </b-card>
              </b-col>
              <b-col lg="4" sm="12">
                <b-card
                  v-if="topVideoSubscribers"
                  no-body
                  class="card-company-table"
                >
                  <b-table
                    :items="topVideoSubscribers"
                    responsive
                    :fields="topVideoSubscribersFields"
                    class="mb-0"
                  >
                    <!-- company -->
                    <template #cell(name)="data">
                      <div class="d-flex align-items-center">
                        <b-avatar rounded size="32" variant="light-company">
                          <b-img :src="data.item.thumbnail" alt="avatar img"
                        /></b-avatar>
                        <div>
                          <div class="font-weight-bolder mr-2 ml-2">
                            {{ data.item.name }}
                          </div>
                        </div>
                      </div>
                    </template>

                    <!-- count -->
                    <template #cell(count)="data">
                      <div class="d-flex flex-column">
                        <span class="font-weight-bolder mb-25">{{
                          data.item.count
                        }}</span>
                      </div>
                    </template>
                  </b-table>
                </b-card>
              </b-col>
              <b-col lg="4" sm="12">
                <b-card
                  v-if="topVideoSubscribersLost"
                  no-body
                  class="card-company-table"
                >
                  <b-table
                    :items="topVideoSubscribersLost"
                    responsive
                    :fields="topVideoSubscribersLostFields"
                    class="mb-0"
                  >
                    <!-- company -->
                    <template #cell(name)="data">
                      <div class="d-flex align-items-center">
                        <b-avatar rounded size="32" variant="light-company">
                          <b-img :src="data.item.thumbnail" alt="avatar img"
                        /></b-avatar>
                        <div>
                          <div class="font-weight-bolder mr-2 ml-2">
                            {{ data.item.name }}
                          </div>
                        </div>
                      </div>
                    </template>

                    <!-- count -->
                    <template #cell(count)="data">
                      <div class="d-flex flex-column">
                        <span class="font-weight-bolder mb-25">{{
                          data.item.count
                        }}</span>
                      </div>
                    </template>
                  </b-table>
                </b-card>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab @click="loadAudienceTab">
            <template #title>
              <span>{{ $t("Pages.Studio.Title.Contacts") }}</span>
            </template>

            <b-row>
              <b-col lg="12" sm="12">
                <b-card no-body>
                  <b-card-header>
                    <div>
                      <b-card-title class="mb-1">
                        {{ $t("Pages.Studio.Title.Subscriber") }}
                      </b-card-title>
                      <b-card-sub-title></b-card-sub-title>
                    </div>
                  </b-card-header>

                  <b-overlay :show="showChart" rounded="sm">
                    <b-card-body v-if="subscribersChart.status">
                      <chartjs-component-line-chart
                        :height="430"
                        :data="subscribersChart.data"
                        :options="subscribersChart.options"
                        :plugins="plugins"
                      />
                    </b-card-body>
                  </b-overlay>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4" sm="12">
                <b-card v-if="topVideoViews" no-body class="card-company-table">
                  <b-table
                    :items="topVideoViews"
                    responsive
                    :fields="topVideoViewsFields"
                    class="mb-0"
                  >
                    <!-- company -->
                    <template #cell(name)="data">
                      <div class="d-flex align-items-center">
                        <b-avatar rounded size="32" variant="light-company">
                          <b-img :src="data.item.thumbnail" alt="avatar img"
                        /></b-avatar>
                        <div>
                          <div class="font-weight-bolder mr-2 ml-2">
                            {{ data.item.name }}
                          </div>
                        </div>
                      </div>
                    </template>

                    <!-- count -->
                    <template #cell(count)="data">
                      <div class="d-flex flex-column">
                        <span class="font-weight-bolder mb-25">{{
                          data.item.count
                        }}</span>
                      </div>
                    </template>
                  </b-table>
                </b-card>
              </b-col>
              <b-col lg="4" sm="12">
                <b-card
                  v-if="topVideoSubscribers"
                  no-body
                  class="card-company-table"
                >
                  <b-table
                    :items="topVideoSubscribers"
                    responsive
                    :fields="topVideoSubscribersFields"
                    class="mb-0"
                  >
                    <!-- company -->
                    <template #cell(name)="data">
                      <div class="d-flex align-items-center">
                        <b-avatar rounded size="32" variant="light-company">
                          <b-img :src="data.item.thumbnail" alt="avatar img"
                        /></b-avatar>
                        <div>
                          <div class="font-weight-bolder mr-2 ml-2">
                            {{ data.item.name }}
                          </div>
                        </div>
                      </div>
                    </template>

                    <!-- count -->
                    <template #cell(count)="data">
                      <div class="d-flex flex-column">
                        <span class="font-weight-bolder mb-25">{{
                          data.item.count
                        }}</span>
                      </div>
                    </template>
                  </b-table>
                </b-card>
              </b-col>
              <b-col lg="4" sm="12">
                <b-card
                  v-if="topVideoSubscribersLost"
                  no-body
                  class="card-company-table"
                >
                  <b-table
                    :items="topVideoSubscribersLost"
                    responsive
                    :fields="topVideoSubscribersLostFields"
                    class="mb-0"
                  >
                    <!-- company -->
                    <template #cell(name)="data">
                      <div class="d-flex align-items-center">
                        <b-avatar rounded size="32" variant="light-company">
                          <b-img :src="data.item.thumbnail" alt="avatar img"
                        /></b-avatar>
                        <div>
                          <div class="font-weight-bolder mr-2 ml-2">
                            {{ data.item.name }}
                          </div>
                        </div>
                      </div>
                    </template>

                    <!-- count -->
                    <template #cell(count)="data">
                      <div class="d-flex flex-column">
                        <span class="font-weight-bolder mb-25">{{
                          data.item.count
                        }}</span>
                      </div>
                    </template>
                  </b-table>
                </b-card>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab @click="loadRevenueTab">
            <template #title>
              <span>{{ $t("Pages.Studio.Title.Income") }}</span>
            </template>

            <b-row>
              <b-col lg="12" sm="12">
                <b-card no-body>
                  <b-card-header>
                    <div>
                      <b-card-title class="mb-1"
                        >${{ revenuesChart.total }}</b-card-title
                      >
                      <b-card-sub-title>{{
                        $t("Pages.Studio.Title.Income")
                      }}</b-card-sub-title>
                    </div>
                  </b-card-header>

                  <b-overlay :show="showChart" rounded="sm">
                    <b-card-body v-if="revenuesChart.status">
                      <chartjs-component-line-chart
                        :height="430"
                        :data="revenuesChart.data"
                        :options="revenuesChart.options"
                        :plugins="plugins"
                      />
                    </b-card-body>
                  </b-overlay>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8" sm="12">
                <b-card no-body>
                  <b-card-header>
                    <div>
                      <b-card-title class="mb-1"
                        >${{ playbackCpmChart.avarage }}</b-card-title
                      >
                      <b-card-sub-title>{{
                        $t("Pages.Studio.Title.playback_CPM")
                      }}</b-card-sub-title>
                    </div>
                  </b-card-header>

                  <!-- chart -->
                  <b-card-body v-if="playbackCpmChart.status">
                    <chartjs-component-bar-chart
                      :height="400"
                      :data="playbackCpmChart.data"
                      :options="playbackCpmChart.options"
                    />
                  </b-card-body>
                </b-card>
              </b-col>
              <b-col lg="4" sm="12">
                <b-card
                  v-if="topVideoRevenues"
                  no-body
                  class="card-company-table"
                >
                  <b-table
                    :items="topVideoRevenues"
                    responsive
                    :fields="topVideoRevenuesFields"
                    class="mb-0"
                  >
                    <!-- company -->
                    <template #cell(name)="data">
                      <div class="d-flex align-items-center">
                        <b-avatar rounded size="32" variant="light-company">
                          <b-img :src="data.item.thumbnail" alt="avatar img"
                        /></b-avatar>
                        <div>
                          <div class="font-weight-bolder mr-2 ml-2">
                            {{ data.item.name }}
                          </div>
                        </div>
                      </div>
                    </template>

                    <!-- count -->
                    <template #cell(count)="data">
                      <div class="d-flex flex-column">
                        <span class="font-weight-bolder mb-25">{{
                          data.item.count
                        }}</span>
                      </div>
                    </template>
                  </b-table>
                </b-card>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab @click="loadAccountingTab" v-if="isAdsense == true">
            <template #title>
              <span>{{ $t("Pages.Studio.Title.Accounting") }}</span>
            </template>

            <b-row>
              <b-col lg="12" sm="12">
                <b-card v-if="data" no-body class="card-statistics">
                  <b-card-body class="statistics-body">
                    <b-row>
                      <b-col xl="3" sm="6">
                        <b-media no-body>
                          <b-media-aside class="mr-2">
                            <b-avatar size="48" variant="light-success">
                              <feather-icon size="24" icon="DollarSignIcon" />
                            </b-avatar>
                          </b-media-aside>
                          <b-media-body class="my-auto">
                            <h4 class="font-weight-bolder mb-0">
                              {{ accounting.totalRevenue }}$
                            </h4>
                            <b-card-text class="font-small-3 mb-0">
                              {{ $t("Pages.Studio.Title.total_income") }}
                            </b-card-text>
                          </b-media-body>
                        </b-media>
                      </b-col>
                      <b-col xl="3" sm="6">
                        <b-media no-body>
                          <b-media-aside class="mr-2">
                            <b-avatar size="48" variant="light-success">
                              <feather-icon size="24" icon="DollarSignIcon" />
                            </b-avatar>
                          </b-media-aside>
                          <b-media-body class="my-auto">
                            <h4 class="font-weight-bolder mb-0">
                              {{ accounting.totalUserRevenue }}$
                            </h4>
                            <b-card-text class="font-small-3 mb-0">
                              {{ $t("Pages.Studio.Title.user_share") }}
                            </b-card-text>
                          </b-media-body>
                        </b-media>
                      </b-col>
                      <b-col xl="3" sm="6">
                        <b-media no-body>
                          <b-media-aside class="mr-2">
                            <b-avatar size="48" variant="light-success">
                              <feather-icon size="24" icon="DollarSignIcon" />
                            </b-avatar>
                          </b-media-aside>
                          <b-media-body class="my-auto">
                            <h4 class="font-weight-bolder mb-0">
                              {{ accounting.totalAdsenseRevenue }}$
                            </h4>
                            <b-card-text class="font-small-3 mb-0">
                              {{ $t("Pages.Studio.Title.admin_share") }}
                            </b-card-text>
                          </b-media-body>
                        </b-media>
                      </b-col>
                      <b-col xl="3" sm="6">
                        <b-media no-body>
                          <b-media-aside class="mr-2">
                            <b-avatar size="48" variant="light-success">
                              <feather-icon size="24" icon="DollarSignIcon" />
                            </b-avatar>
                          </b-media-aside>
                          <b-media-body class="my-auto">
                            <h4 class="font-weight-bolder mb-0">
                              {{ accounting.totalPaymentAdsense }}$
                            </h4>
                            <b-card-text class="font-small-3 mb-0">
                              {{ $t("Pages.Studio.Title.user_deposit") }}
                            </b-card-text>
                          </b-media-body>
                        </b-media>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12" sm="12">
                <b-card
                  v-if="accounting.payments"
                  no-body
                  class="card-company-table"
                >
                  <b-table
                    :items="accounting.payments"
                    responsive
                    :fields="accounting.paymentsFields"
                    class="mb-0"
                  >
                    <template #cell(amount)="data">
                      <div class="d-flex flex-column">
                        <span class="font-weight-bolder mb-25">{{
                          data.item.euro
                        }}</span>
                      </div>
                    </template>

                    <template #cell(date)="data">
                      <div class="d-flex flex-column">
                        <span class="font-weight-bolder mb-25">{{
                          MyDate.getFormatDate(data.item.created_at)
                        }}</span>
                      </div>
                    </template>

                    <template #cell(desc)="data">
                      <div class="d-flex flex-column">
                        <span class="font-weight-bolder mb-25">{{
                          data.item.description
                        }}</span>
                      </div>
                    </template>
                  </b-table>
                </b-card>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardGroup,
  BCardText,
  BLink,
  BCardHeader,
  BCardBody,
  BCardSubTitle,
  BCardTitle,
  BImg,
  BButton,
  BMedia,
  BMediaAside,
  BTable,
  VBTooltip,
  BMediaBody,
  BAvatar,
  BTabs,
  BTab,
  BOverlay,
  BBadge,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import ChartjsComponentLineChart from "@/views/charts/charts-components/ChartjsComponentLineChart.vue";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import ChartjsComponentBarChart from "@/views/charts/charts-components/ChartjsComponentBarChart.vue";
import { kFormatter } from "@core/utils/filter";
import Ripple from "vue-ripple-directive";
import { $themeColors } from "@themeConfig";
import MyDate from "@core/components/date/MyDate.vue";
import chartjsData from "../../charts/chartjsData";
import ErrorMessage from "@/layouts/components/ErrorMessage.vue";
const $strokeColor = "#ebe9f1";
const $goalStrokeColor2 = "#51e5a8";

const chartColors = {
  primaryColorShade: "#836AF9",
  yellowColor: "#ffe800",
  successColorShade: "#28dac6",
  warningColorShade: "#ffe802",
  warningLightColor: "#FDAC34",
  infoColorShade: "#299AFF",
  greyColor: "#4F5D70",
  blueColor: "#2c9aff",
  blueLightColor: "#84D0FF",
  greyLightColor: "#EDF1F4",
  tooltipShadow: "rgba(0, 0, 0, 0.25)",
  lineChartPrimary: "#666ee8",
  lineChartDanger: "#ff4961",
  labelColor: "#6e6b7b",
  grid_line_color: "rgba(200, 200, 200, 0.2)",
  column: {
    series1: "#826af9",
    series2: "#d2b0ff",
    bg: "#f8d3ff",
  },
  success: {
    shade_100: "#7eefc7",
    shade_200: "#06774f",
  },
  donut: {
    series1: "#ffe700",
    series2: "#00d4bd",
    series3: "#826bf8",
    series4: "#2b9bf4",
    series5: "#FFA1A1",
  },
  area: {
    series3: "#a4f8cd",
    series2: "#60f2ca",
    series1: "#2bdac7",
  },
};

export default {
  components: {
    StatisticCardWithAreaChart,
    BCard,
    BTable,
    BCol,
    BImg,
    BRow,
    BAvatar,
    BButton,
    BCardHeader,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
    BMediaBody,
    BMedia,
    BMediaAside,
    BCardGroup,
    ChartjsComponentLineChart,
    ChartjsComponentBarChart,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BOverlay,
    BBadge,
    VueApexCharts,
    ErrorMessage,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      MyDate,
      topVideoWatchTime: [],
      topVideoWatchTimeFields: [
        { key: "name", label: this.$t("Pages.Studio.Title.top_ten_vid") },
        { key: "count", label: this.$t("Pages.Studio.Title.watch_time") },
      ],
      topVideoSubscribers: [],
      topVideoSubscribersFields: [
        { key: "name", label: this.$t("Pages.Studio.Title.top_ten_vid") },
        { key: "count", label: this.$t("Pages.Studio.Title.Subscriber") },
      ],
      topVideoSubscribersLost: [],
      topVideoSubscribersLostFields: [
        { key: "name", label: this.$t("Pages.Studio.Title.top_ten_vid") },
        {
          key: "count",
          label: this.$t("Pages.Studio.Title.missed_subscriber"),
        },
      ],
      topVideoViews: [],
      topVideoViewsFields: [
        { key: "name", label: this.$t("Pages.Studio.Title.top_ten_vid") },
        { key: "count", label: this.$t("Pages.Studio.Title.View") },
      ],
      topVideoRevenues: [],
      topVideoRevenuesFields: [
        { key: "name", label: this.$t("Pages.Studio.Title.top_ten_vid") },
        { key: "count", label: this.$t("Pages.Studio.Title.Income") },
      ],
      showOverView: true,
      showChart: true,
      data: {
        name: "me",
        saleToday: "200",
        mediaData: [],
        views: {
          status: false,
          series: [
            {
              name: "Views",
              data: [],
            },
          ],
          analyticsData: 0,
        },
        watchtime: {
          status: false,
          series: [
            {
              name: "WatchTime",
              data: [],
            },
          ],
          analyticsData: 0,
        },
        subscribers: {
          status: false,
          series: [
            {
              name: "Subscribers",
              data: [],
            },
          ],
          analyticsData: 0,
        },
        subscriberslost: {
          status: false,
          series: [
            {
              name: "Subscribers Lost",
              data: [],
            },
          ],
          analyticsData: 0,
        },
        like: {
          status: false,
          series: [
            {
              name: "Like",
              data: [],
            },
          ],
          analyticsData: 0,
        },
        dislike: {
          status: false,
          series: [
            {
              name: "Dislike",
              data: [],
            },
          ],
          analyticsData: 0,
        },
        share: {
          status: false,
          series: [
            {
              name: "Share",
              data: [],
            },
          ],
          analyticsData: 0,
        },
        comment: {
          status: false,
          series: [
            {
              name: "Comment",
              data: [],
            },
          ],
          analyticsData: 0,
        },
      },
      impressionsChart: {
        status: false,
        data: {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: chartColors.successColorShade,
              borderColor: "transparent",
            },
          ],
        },
        options: {
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: "bottom",
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: false,
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                scaleLabel: {
                  display: false,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                ticks: {
                  stepSize: 500,
                  min: 0,
                  max: 0,
                  fontColor: chartColors.labelColor,
                },
              },
            ],
          },
        },
      },
      impressionCpmChart: {
        status: false,
        data: {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: chartColors.infoColorShade,
              borderColor: "transparent",
            },
          ],
        },
        options: {
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: "bottom",
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: false,
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                scaleLabel: {
                  display: false,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                ticks: {
                  stepSize: 100,
                  min: 0,
                  max: 0,
                  fontColor: chartColors.labelColor,
                },
              },
            ],
          },
        },
      },
      viewChart: {
        status: false,
        total: 0,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          backgroundColor: false,
          hover: {
            mode: "label",
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.black,
            bodyFontColor: $themeColors.black,
          },
          layout: {
            padding: {
              top: -15,
              bottom: -25,
              left: -15,
            },
          },
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                },
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  stepSize: 100,
                  min: 0,
                  max: 0,
                  fontColor: chartColors.labelColor,
                },
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
              },
            ],
          },
          legend: {
            position: "top",
            align: "start",
            labels: {
              usePointStyle: true,
              padding: 25,
              boxWidth: 9,
            },
          },
        },
        data: {
          labels: [],
          datasets: [
            {
              data: [],
              label: "",
              borderColor: chartColors.lineChartDanger,
              lineTension: 0.5,
              pointStyle: "circle",
              backgroundColor: chartColors.lineChartDanger,
              fill: false,
              pointRadius: 3,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: "transparent",
              pointHoverBorderColor: $themeColors.white,
              pointHoverBackgroundColor: chartColors.lineChartDanger,
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
              pointShadowColor: chartColors.tooltipShadow,
            },
          ],
        },
      },
      watchtimeChart: {
        status: false,
        data: {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: chartColors.successColorShade,
              borderColor: "transparent",
            },
          ],
        },
        options: {
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: "bottom",
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: false,
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                scaleLabel: {
                  display: false,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                ticks: {
                  stepSize: 500,
                  min: 0,
                  max: 0,
                  fontColor: chartColors.labelColor,
                },
              },
            ],
          },
        },
      },
      avgdurationChart: {
        status: false,
        data: {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: chartColors.infoColorShade,
              borderColor: "transparent",
            },
          ],
        },
        options: {
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: "bottom",
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: false,
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                scaleLabel: {
                  display: false,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                ticks: {
                  stepSize: 100,
                  min: 0,
                  max: 0,
                  fontColor: chartColors.labelColor,
                },
              },
            ],
          },
        },
      },
      cardImpressionsChart: {
        status: false,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          backgroundColor: false,
          hover: {
            mode: "label",
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.black,
            bodyFontColor: $themeColors.black,
          },
          layout: {
            padding: {
              top: -15,
              bottom: -25,
              left: -15,
            },
          },
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                },
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  stepSize: 100,
                  min: 0,
                  max: 0,
                  fontColor: chartColors.labelColor,
                },
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
              },
            ],
          },
          legend: {
            position: "top",
            align: "start",
            labels: {
              usePointStyle: true,
              padding: 25,
              boxWidth: 9,
            },
          },
        },
        data: {
          labels: [],
          datasets: [
            {
              data: [],
              label: "",
              borderColor: chartColors.lineChartDanger,
              lineTension: 0.5,
              pointStyle: "circle",
              backgroundColor: chartColors.lineChartDanger,
              fill: false,
              pointRadius: 3,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: "transparent",
              pointHoverBorderColor: $themeColors.white,
              pointHoverBackgroundColor: chartColors.lineChartDanger,
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
              pointShadowColor: chartColors.tooltipShadow,
            },
          ],
        },
      },
      subscribersChart: {
        status: false,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          backgroundColor: false,
          hover: {
            mode: "label",
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.black,
            bodyFontColor: $themeColors.black,
          },
          layout: {
            padding: {
              top: -15,
              bottom: -25,
              left: -15,
            },
          },
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                },
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  stepSize: 100,
                  min: 0,
                  max: 0,
                  fontColor: chartColors.labelColor,
                },
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
              },
            ],
          },
          legend: {
            position: "top",
            align: "start",
            labels: {
              usePointStyle: true,
              padding: 25,
              boxWidth: 9,
            },
          },
        },
        data: {
          labels: [],
          datasets: [
            {
              data: [],
              label: "",
              borderColor: chartColors.lineChartDanger,
              lineTension: 0.5,
              pointStyle: "circle",
              backgroundColor: chartColors.lineChartDanger,
              fill: false,
              pointRadius: 3,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: "transparent",
              pointHoverBorderColor: $themeColors.white,
              pointHoverBackgroundColor: chartColors.lineChartDanger,
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
              pointShadowColor: chartColors.tooltipShadow,
            },
          ],
        },
      },
      revenuesChart: {
        status: false,
        total: 0,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          backgroundColor: false,
          hover: {
            mode: "label",
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.black,
            bodyFontColor: $themeColors.black,
          },
          layout: {
            padding: {
              top: -15,
              bottom: -25,
              left: -15,
            },
          },
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                },
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  stepSize: 100,
                  min: 0,
                  max: 0,
                  fontColor: chartColors.labelColor,
                },
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
              },
            ],
          },
          legend: {
            position: "top",
            align: "start",
            labels: {
              usePointStyle: true,
              padding: 25,
              boxWidth: 9,
            },
          },
        },
        data: {
          labels: [],
          datasets: [
            {
              data: [],
              label: "$",
              borderColor: chartColors.lineChartDanger,
              lineTension: 0.5,
              pointStyle: "circle",
              backgroundColor: chartColors.lineChartDanger,
              fill: false,
              pointRadius: 3,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: "transparent",
              pointHoverBorderColor: $themeColors.white,
              pointHoverBackgroundColor: chartColors.lineChartDanger,
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
              pointShadowColor: chartColors.tooltipShadow,
            },
          ],
        },
      },
      playbackCpmChart: {
        status: false,
        avarage: 0,
        data: {
          labels: [],
          datasets: [
            {
              label: "$",
              data: [],
              backgroundColor: chartColors.successColorShade,
              borderColor: "transparent",
            },
          ],
        },
        options: {
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: "bottom",
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: false,
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                scaleLabel: {
                  display: false,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                ticks: {
                  stepSize: 500,
                  min: 0,
                  max: 0,
                  fontColor: chartColors.labelColor,
                },
              },
            ],
          },
        },
      },
      accounting: {
        totalRevenue: "0",
        totalAdsenseRevenue: "0",
        totalUserRevenue: "0",
        totalPaymentAdsense: "0",
        showTable: false,
        payments: [],
        paymentsFields: [
          { key: "amount", label: this.$t("Pages.Studio.Table.euro_amount") },
          {
            key: "date",
            label: this.$t("Pages.Studio.Table.registration_date"),
          },
          { key: "desc", label: this.$t("Pages.Studio.Table.Description") },
        ],
      },
      userNews: [],
      text1: "",
      membership: {},
      isAdsense: false,
      channelInfo: {
        id: null,
      },
      plugins: [
        {
          beforeInit(chart) {
            chart.legend.afterFit = function () {
              this.height += 20;
            };
          },
        },
      ],
      tableDataDepositAccounting: [],
      fieldsDepositAccounting: [
        {
          key: "financial_month",
          label: this.$t("Pages.Studio.Table.financial_month"),
          sortable: true,
        },
        {
          key: "revenue",
          label: `${this.$t("Pages.Studio.Table.total_income")} (€)`,
          sortable: true,
        },
        {
          key: "revenue_adsense",
          label: `${this.$t("Pages.Studio.Table.admin_share")} (€)`,
          sortable: true,
        },
        {
          key: "revenue_user",
          label: `${this.$t("Pages.Studio.Table.user_share")} (€)`,
          sortable: true,
        },
        {
          key: "exchange_range",
          label: `${this.$t("Pages.Studio.Table.exchange_range")}`,
          sortable: true,
        },
        {
          key: "deposit_toman",
          label: `${this.$t("Pages.Studio.Table.deposit_toman")}`,
          sortable: true,
        },
        {
          key: "payment_status",
          label: this.$t("Pages.Studio.Table.payment_status"),
        },
        {
          key: "payment_receipt",
          label: this.$t("Pages.Studio.Table.payment_receipt"),
        },
      ],
      goalOverviewRadialBar: {
        chart: {
          height: 245,
          type: "radialBar",
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: [$goalStrokeColor2],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: "77%",
            },
            track: {
              background: $strokeColor,
              strokeWidth: "50%",
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                color: $themeColors.success,
                fontSize: "2.86rem",
                fontWeight: "600",
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: [$themeColors.success],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: "round",
        },
        grid: {
          padding: {
            bottom: 30,
          },
        },
      },
      goalOverview: {
        series: [],
        channel: [],
        videos: [],
        dataChannel: [],
      },
      chartjsData,
      hasVideoData: false,
      channel_url: null,
    };
  },
  created() {
    this.getChannelInfo();
    this.channel_url =
      "https://www.youtube.com/channel/" + this.$route.params.channel_id;
  },
  mounted() {},
  watch: {
    $route(to, from) {
      if (to.query != from.query) {
        this.refreshAll();
      }
    },
  },
  methods: {
    kFormatter,
    async getChannelInfo() {
      try {
        const channelId = this.$route.params.channel_id;
        const response = await this.$http.get(
          `/api/channel/${channelId}/getInformation`
        );
        this.channelInfo = response.data;
        this.refreshAll();
      } catch (error) {
        console.error("Error while fetching channel information:", error);
      }
    },
    checkAdsense() {
      this.$http
        .get(`/api/channel/${this.channelInfo.channel_id}/checkAdsense`)
        .then((response) => {
          this.isAdsense = response.data;
        });
    },
    add() {
      this.$http.get("/api/channel/add").then((response) => {
        window.location.href = response.data;
      });
    },
    loadOverviewTab() {
      this.data.views.status = false;
      this.data.watchtime.status = false;
      this.data.subscribers.status = false;
      this.data.subscriberslost.status = false;
      this.data.like.status = false;
      this.data.dislike.status = false;
      this.data.share.status = false;
      this.data.comment.status = false;

      this.$http
        .get(`/api/channel/${this.channelInfo.channel_id}/getViewData`, {
          params: {
            start: this.$route.query.start,
            end: this.$route.query.end,
          },
        })
        .then((response) => {
          this.data.views.status = true;
          this.data.views.series[0].data = response.data.data;
          this.data.views.analyticsData = response.data.total;
        });

      this.$http
        .get(`/api/channel/${this.channelInfo.channel_id}/getWatchTimeData`, {
          params: {
            start: this.$route.query.start,
            end: this.$route.query.end,
          },
        })
        .then((response) => {
          this.data.watchtime.status = true;
          this.data.watchtime.series[0].data = response.data.data;
          this.data.watchtime.analyticsData = response.data.total;
        });

      this.$http
        .get(`/api/channel/${this.channelInfo.channel_id}/getSubscribersData`, {
          params: {
            start: this.$route.query.start,
            end: this.$route.query.end,
          },
        })
        .then((response) => {
          this.data.subscribers.status = true;
          this.data.subscribers.series[0].data = response.data.data;
          this.data.subscribers.analyticsData = response.data.total;
        });

      this.$http
        .get(
          `/api/channel/${this.channelInfo.channel_id}/getSubscribersLostData`,
          {
            params: {
              start: this.$route.query.start,
              end: this.$route.query.end,
            },
          }
        )
        .then((response) => {
          this.data.subscriberslost.status = true;
          this.data.subscriberslost.series[0].data = response.data.data;
          this.data.subscriberslost.analyticsData = response.data.total;
        });

      this.$http
        .get(`/api/channel/${this.channelInfo.channel_id}/getLikesData`, {
          params: {
            start: this.$route.query.start,
            end: this.$route.query.end,
          },
        })
        .then((response) => {
          this.data.like.status = true;
          this.data.like.series[0].data = response.data.data;
          this.data.like.analyticsData = response.data.total;
        });

      this.$http
        .get(`/api/channel/${this.channelInfo.channel_id}/getDisLikesData`, {
          params: {
            start: this.$route.query.start,
            end: this.$route.query.end,
          },
        })
        .then((response) => {
          this.data.dislike.status = true;
          this.data.dislike.series[0].data = response.data.data;
          this.data.dislike.analyticsData = response.data.total;
        });

      this.$http
        .get(`/api/channel/${this.channelInfo.channel_id}/getSharesData`, {
          params: {
            start: this.$route.query.start,
            end: this.$route.query.end,
          },
        })
        .then((response) => {
          this.data.share.status = true;
          this.data.share.series[0].data = response.data.data;
          this.data.share.analyticsData = response.data.total;
        });

      this.$http
        .get(`/api/channel/${this.channelInfo.channel_id}/getCommentsData`, {
          params: {
            start: this.$route.query.start,
            end: this.$route.query.end,
          },
        })
        .then((response) => {
          this.data.comment.status = true;
          this.data.comment.series[0].data = response.data.data;
          this.data.comment.analyticsData = response.data.total;
        });
      this.showOverView = false;
    },
    loadReachTab() {
      this.impressionsChart.status = false;
      this.impressionCpmChart.status = false;
      this.viewChart.status = false;

      this.$http
        .get(`/api/channel/${this.channelInfo.channel_id}/getViewChart`, {
          params: {
            start: this.$route.query.start,
            end: this.$route.query.end,
          },
        })
        .then((response) => {
          this.viewChart.status = true;
          this.viewChart.total = response.data.total;
          this.viewChart.data.labels = response.data.labels;
          this.viewChart.data.datasets[0].data = response.data.dataView;
          this.viewChart.options.scales.yAxes[0].ticks.max = response.data.max;
        });

      this.$http
        .get(
          `/api/channel/${this.channelInfo.channel_id}/getImpressionsChart`,
          {
            params: {
              start: this.$route.query.start,
              end: this.$route.query.end,
            },
          }
        )
        .then((response) => {
          this.impressionsChart.status = true;
          this.impressionsChart.data.labels = response.data.labels;
          this.impressionsChart.data.datasets[0].data =
            response.data.dataImpressions;
          this.impressionsChart.options.scales.yAxes[0].ticks.max =
            response.data.max;
        });

      this.$http
        .get(
          `/api/channel/${this.channelInfo.channel_id}/getImpressionsCpmChart`,
          {
            params: {
              start: this.$route.query.start,
              end: this.$route.query.end,
            },
          }
        )
        .then((response) => {
          this.impressionCpmChart.status = true;
          this.impressionCpmChart.data.labels = response.data.labels;
          this.impressionCpmChart.data.datasets[0].data =
            response.data.dataImpressionCpm;
          this.impressionCpmChart.options.scales.yAxes[0].ticks.max =
            response.data.max;
          this.showChart = false;
        });
    },
    loadEngagementTab() {
      this.watchtimeChart.status = false;
      this.avgdurationChart.status = false;
      this.cardImpressionsChart.status = false;

      this.$http
        .get(`/api/channel/${this.channelInfo.channel_id}/getWatchTimeChart`, {
          params: {
            start: this.$route.query.start,
            end: this.$route.query.end,
          },
        })
        .then((response) => {
          this.watchtimeChart.status = true;
          this.watchtimeChart.data.labels = response.data.labels;
          this.watchtimeChart.data.datasets[0].data =
            response.data.dataWatchtime;
          this.watchtimeChart.options.scales.yAxes[0].ticks.max =
            response.data.max;
        });

      this.$http
        .get(
          `/api/channel/${this.channelInfo.channel_id}/getAvgDurationChart`,
          {
            params: {
              start: this.$route.query.start,
              end: this.$route.query.end,
            },
          }
        )
        .then((response) => {
          this.avgdurationChart.status = true;
          this.avgdurationChart.data.labels = response.data.labels;
          this.avgdurationChart.data.datasets[0].data =
            response.data.dataAvgDuration;
          this.avgdurationChart.options.scales.yAxes[0].ticks.max =
            response.data.max;
        });

      this.$http
        .get(
          `/api/channel/${this.channelInfo.channel_id}/getCardImpressionsChart`,
          {
            params: {
              start: this.$route.query.start,
              end: this.$route.query.end,
            },
          }
        )
        .then((response) => {
          this.cardImpressionsChart.status = true;
          this.cardImpressionsChart.data.labels = response.data.labels;
          this.cardImpressionsChart.data.datasets[0].data =
            response.data.dataCardImpressions;
          this.cardImpressionsChart.options.scales.yAxes[0].ticks.max =
            response.data.max;
          this.showChart = false;
        });

      this.$http
        .get(`/api/channel/${this.channelInfo.channel_id}/getTopVideoWatchTime`)
        .then((response) => {
          this.topVideoWatchTime = response.data;
        });

      this.$http
        .get(
          `/api/channel/${this.channelInfo.channel_id}/getTopVideoSubscribers`
        )
        .then((response) => {
          this.topVideoSubscribers = response.data;
        });

      this.$http
        .get(
          `/api/channel/${this.channelInfo.channel_id}/getTopVideoSubscribersLost`
        )
        .then((response) => {
          this.topVideoSubscribersLost = response.data;
        });
    },
    loadAudienceTab() {
      this.subscribersChart.status = false;

      this.$http
        .get(
          `/api/channel/${this.channelInfo.channel_id}/getSubscribersChart`,
          {
            params: {
              start: this.$route.query.start,
              end: this.$route.query.end,
            },
          }
        )
        .then((response) => {
          this.subscribersChart.status = true;
          this.subscribersChart.data.labels = response.data.labels;
          this.subscribersChart.data.datasets[0].data =
            response.data.dataSubscribers;
          this.subscribersChart.options.scales.yAxes[0].ticks.max =
            response.data.max;
          this.showChart = false;
        });

      this.$http
        .get(
          `/api/channel/${this.channelInfo.channel_id}/getTopVideoSubscribers`
        )
        .then((response) => {
          this.topVideoSubscribers = response.data;
        });

      this.$http
        .get(
          `/api/channel/${this.channelInfo.channel_id}/getTopVideoSubscribersLost`
        )
        .then((response) => {
          this.topVideoSubscribersLost = response.data;
        });

      this.$http
        .get(`/api/channel/${this.channelInfo.channel_id}/getTopVideoViews`)
        .then((response) => {
          this.topVideoViews = response.data;
        });
    },
    loadRevenueTab() {
      this.revenuesChart.status = false;
      this.playbackCpmChart.status = false;

      this.$http
        .get(`/api/channel/${this.channelInfo.channel_id}/getRevenueChart`, {
          params: {
            start: this.$route.query.start,
            end: this.$route.query.end,
          },
        })
        .then((response) => {
          this.revenuesChart.status = true;
          this.revenuesChart.total = response.data.total;
          this.revenuesChart.data.labels = response.data.labels;
          this.revenuesChart.data.datasets[0].data = response.data.dataRevenues;
          this.revenuesChart.options.scales.yAxes[0].ticks.max =
            response.data.max;
          this.showChart = false;
        });

      this.$http
        .get(
          `/api/channel/${this.channelInfo.channel_id}/getPlaybackCpmChart`,
          {
            params: {
              start: this.$route.query.start,
              end: this.$route.query.end,
            },
          }
        )
        .then((response) => {
          this.playbackCpmChart.status = true;
          this.playbackCpmChart.avarage = response.data.avarage;
          this.playbackCpmChart.data.labels = response.data.labels;
          this.playbackCpmChart.data.datasets[0].data =
            response.data.dataPlaybackCpm;
          this.playbackCpmChart.options.scales.yAxes[0].ticks.max =
            response.data.max;
          this.showChart = false;
        });

      this.$http
        .get(`/api/channel/${this.channelInfo.channel_id}/getTopVideoRevenues`)
        .then((response) => {
          this.topVideoRevenues = response.data;
        });
    },
    loadAccountingTab() {},
    getCheckListData() {
      this.$http.get("/api/tools/checklist/data", {
        params: {
          id: this.channelInfo.channel_id,
        },
      });
    },
    setChartVideoPeriodData(data, max) {
      var labels = [];
      var values = [];
      for (const [index, element] of Object.entries(data)) {
        labels.push(index);
        values.push(element);
      }
      this.chartjsData.lineChart.options.scales.yAxes[0].ticks.max = max;
      this.chartjsData.lineChart.data.labels = labels;
      this.chartjsData.lineChart.data.datasets[0].data = values;
      this.chartjsData.lineChart.status = true;
    },
    moreAnalyze() {
      if (this.channelInfo.user.membership.status) {
        this.$router.push({
          path: "/tools/checklist/info",
          query: { id: this.channelInfo.channel_id },
        });
      } else {
        this.$router.push("/membership").catch(() => {});
      }
    },
    goToAccountingPage() {
      this.$router.push({
        path: "/channel/accounting/details",
        query: { id: this.channelInfo.channel_id },
      });
    },
    refreshAll() {
      this.loadOverviewTab();
      this.loadReachTab();
      this.loadEngagementTab();
      this.loadAudienceTab();
      this.loadRevenueTab();
      this.loadAccountingTab();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>

<style>
channel-avatar:hover {
  opacity: 0.5;
}
</style>
